import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Button from '../components/Button'
import Heading from '../components/Heading'
import Layout from '../components/layout'
import illustration from '../images/undraw_work_chat_erdt.svg'
import SEO from '../components/seo'

const Content = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  aside {
    margin-bottom: 20px;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Content>
      <aside>
        <Heading>
          Fast, simple
          <br />
          and fun.
        </Heading>
        <p style={{ maxWidth: '400px', margin: '34px 0 76px' }}>
          Revote enables you and your team to <strong>collaborate</strong> and
          collect estimates <strong>anonymously</strong> from{' '}
          <strong>where ever</strong> you choose to work from.
        </p>
        <Button to="/room" as={Link}>
          Create room
        </Button>
      </aside>
      <aside>
        <img
          src={illustration}
          alt="Team working together remotely"
          style={{ maxWidth: '600px', width: '100%' }}
        />
      </aside>
    </Content>
  </Layout>
)

export default IndexPage
